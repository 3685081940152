exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-e-labels-index-tsx": () => import("./../../../src/pages/e-labels/index.tsx" /* webpackChunkName: "component---src-pages-e-labels-index-tsx" */),
  "component---src-pages-handel-angebot-enterprise-js": () => import("./../../../src/pages/handel/angebot-enterprise.js" /* webpackChunkName: "component---src-pages-handel-angebot-enterprise-js" */),
  "component---src-pages-handel-angebot-js": () => import("./../../../src/pages/handel/angebot.js" /* webpackChunkName: "component---src-pages-handel-angebot-js" */),
  "component---src-pages-handel-demo-js": () => import("./../../../src/pages/handel/demo.js" /* webpackChunkName: "component---src-pages-handel-demo-js" */),
  "component---src-pages-handel-index-js": () => import("./../../../src/pages/handel/index.js" /* webpackChunkName: "component---src-pages-handel-index-js" */),
  "component---src-pages-handel-kontakt-js": () => import("./../../../src/pages/handel/kontakt.js" /* webpackChunkName: "component---src-pages-handel-kontakt-js" */),
  "component---src-pages-success-js": () => import("./../../../src/pages/success.js" /* webpackChunkName: "component---src-pages-success-js" */),
  "component---src-pages-weinveranstaltungen-angebot-enterprise-js": () => import("./../../../src/pages/weinveranstaltungen/angebot-enterprise.js" /* webpackChunkName: "component---src-pages-weinveranstaltungen-angebot-enterprise-js" */),
  "component---src-pages-weinveranstaltungen-angebot-js": () => import("./../../../src/pages/weinveranstaltungen/angebot.js" /* webpackChunkName: "component---src-pages-weinveranstaltungen-angebot-js" */),
  "component---src-pages-weinveranstaltungen-demo-js": () => import("./../../../src/pages/weinveranstaltungen/demo.js" /* webpackChunkName: "component---src-pages-weinveranstaltungen-demo-js" */),
  "component---src-pages-weinveranstaltungen-index-js": () => import("./../../../src/pages/weinveranstaltungen/index.js" /* webpackChunkName: "component---src-pages-weinveranstaltungen-index-js" */),
  "component---src-pages-weinveranstaltungen-kontakt-js": () => import("./../../../src/pages/weinveranstaltungen/kontakt.js" /* webpackChunkName: "component---src-pages-weinveranstaltungen-kontakt-js" */),
  "component---src-pages-winzer-demo-js": () => import("./../../../src/pages/winzer/demo.js" /* webpackChunkName: "component---src-pages-winzer-demo-js" */),
  "component---src-pages-winzer-index-js": () => import("./../../../src/pages/winzer/index.js" /* webpackChunkName: "component---src-pages-winzer-index-js" */),
  "component---src-pages-winzer-kontakt-js": () => import("./../../../src/pages/winzer/kontakt.js" /* webpackChunkName: "component---src-pages-winzer-kontakt-js" */)
}

